import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Emitter from 'tiny-emitter' 
import store from './store'
import AADAuthorizationAxiosWrapper from './httpClientWrapper'
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faDungeon, faUsers, faBook, faSignOut, faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { faAngleRight, faPlus, faTimes, faGrid } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faDungeon, faUsers, faBook, faSignOut, faSpinnerThird, faAngleRight, faPlus, faTimes, faGrid)

const app = createApp(App);

app.config.globalProperties.$msalInstance = {};
app.config.globalProperties.$emitter = new Emitter();
app.config.globalProperties.$axiosInstance = AADAuthorizationAxiosWrapper.getAxiosInstance(store);

app.component('font-awesome-icon', FontAwesomeIcon)
app.use(router)
app.use(store)
app.use(VueToast);

app.mount('#app');
