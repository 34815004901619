import { createStore } from 'vuex'

const store = createStore({
    state() {
      return {
        msalConfig: {
          auth: {
            clientId: `${process.env.VUE_APP_AD_CLIENT_ID}`,
            authority: `${process.env.VUE_APP_AD_LOGIN_AUTHORITY}`,
            redirectUri: "/",
          },
          cache: {
            cacheLocation: 'localStorage',
          },
        },
        accessToken: ''
      };
    },
    mutations :{
      setAccessToken(state, token){
        state.accessToken = token;
      }
    }
  });
  
  export default store;