import { createRouter, createWebHistory } from 'vue-router'

import GatesContainer from '@/views/GatesContainer.vue';
import GatesOverview from '@/views/gates/GatesOverview.vue'
import GateDetail from '@/views/gates/GatesDetail.vue'
import Roles from '@/views/roles/Roles.vue'

import NotFound from '@/views/NotFound.vue'
import ObjectsContainer from "@/views/ObjectsContainer";
import ObjectsOverview from "@/views/objects/ObjectsOverview";
import ObjectDetail from "@/views/objects/ObjectDetail";

const routes = [
  {
    path: '/',
    redirect: { name: 'GatesOverview' },
  },
  {
    path: '/poorten',
    component: GatesContainer,
    children: [
      {
        path: '',
        name: 'GatesOverview',
        component: GatesOverview,
      },
      {
        path: ':id',
        name: 'GateDetail',
        component: GateDetail
      },
    ]
  },
    {
        path: '/objecten',
        component: ObjectsContainer,
        children: [
            {
                path: '',
                name: 'ObjectsOverview',
                component: ObjectsOverview,
            },
            {
                path: ':id',
                name: 'ObjectDetail',
                component: ObjectDetail
            },
            {
                path: 'toevoegen',
                name: 'ObjectAdd',
                component: ObjectDetail
            },
        ]
    },
  {
    path: '/gebruikers',
    component: Roles,
    name: 'Roles'
  },
  { 
    path: '/:catchAll(.*)', 
    component: NotFound,
    name: 'NotFound'
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
