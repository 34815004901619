<template>
  <div class="not-found">
    <div>
      <h1>Pagina niet gevonden</h1>
      <p>Probeer één van de links in het menu</p>
      <p>Of klik hier om naar het <router-link to="/poorten">Poorten overzicht</router-link> te gaan</p>
      
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound'
}
</script>

<style lang="scss" scoped>
  .not-found {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  h1 {
    font-size: 4rem;
    margin-bottom: 3rem;
    font-weight: 300;
  }
  p {
    font-weight: 300;
    font-size: 1.25rem;
  }
</style>