import axios from 'axios'
import { PublicClientApplication } from '@azure/msal-browser';
import { Store } from 'vuex';

export default class AADAuthorizationAxiosWrapper {

    static getAxiosInstance(store) {
        if(!(store instanceof Store)) {
            throw 'Store is not a proper vuex store'
        }

        const instance = axios.create();

        instance.interceptors.request.use(async config => {
            if(store.state.accessToken == '') {
                await AADAuthorizationAxiosWrapper.refreshAccessToken(store);                   
            }
            
            config.headers = { 
                'Authorization': `Bearer ${store.state.accessToken}`,
                'Accept': '*/*',
            }
        
            return config;
        }, error => {
            Promise.reject(error)
        });
    
        instance.interceptors.response.use((reponse) => {
            return reponse;
        }, async function (error) {
            const originalRequest = error.config;

            if(error.response.status === 403 && !originalRequest._retry && await AADAuthorizationAxiosWrapper.refreshAccessToken(store)) {
                originalRequest._retry = true;
                originalRequest.headers['Authorization'] = `Bearer ${store.state.accessToken}`;

                return instance(originalRequest);
            }

            return Promise.reject(error);
        });
        
        return instance;
    }

    static async refreshAccessToken(store){
        let request = {
            scopes: [process.env.VUE_APP_API_SCOPE],
        };

        const msalInstance = new PublicClientApplication(store.state.msalConfig);

        try {
            let tokenResponse = await msalInstance.acquireTokenSilent(request);
            store.commit('setAccessToken', tokenResponse.accessToken);

            return true;
        } catch (error) {
            msalInstance.acquireTokenRedirect(request);    
        }
        
        return false;
    }
}