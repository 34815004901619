<template>
  <aside class="aside">
        <img src="@/assets/img/logo-white.svg" class="aside-logo">
        <nav class="aside-nav" v-if="hasPermissions">
            <ul class="aside-menu">
                <li class="aside-menu-item">
                    <router-link class="aside-menu-link" :to="'/poorten'">
                        <span class="aside-menu-icon">
                            <font-awesome-icon :icon="['fad', 'dungeon']" />
                        </span>
                        <span class="aside-menu-label">Poorten</span>
                    </router-link>
                </li>
                <li class="aside-menu-item">
                    <router-link class="aside-menu-link" :to="'/objecten'">
                        <span class="aside-menu-icon">
                            <font-awesome-icon :icon="['far','grid']" />
                        </span>
                        <span class="aside-menu-label">Objecten</span>
                    </router-link>
                </li>
                <li class="aside-menu-item">
                    <router-link class="aside-menu-link" :to="'/gebruikers'">
                        <span class="aside-menu-icon">
                            <font-awesome-icon :icon="['fad', 'users']" />
                        </span>
                        <span class="aside-menu-label">Gebruikers</span>
                    </router-link>
                </li>
                <li class="aside-menu-item">
                    <button class="aside-menu-link" @click="downloadGateLog">
                        <span class="aside-menu-icon">
                            <font-awesome-icon :icon="['fad', 'book']" />
                        </span>
                        <span class="aside-menu-label">Logboek</span>
                    </button>
                </li>
            </ul>
        </nav>

        <nav class="aside-nav" v-else></nav>

        <div class="aside-bottom">
            <nav class="aside-nav">
                <ul class="aside-menu">
                    <li class="aside-menu-item">
                        <button class="aside-menu-link" @click="signOut">
                            <span class="aside-menu-icon">
                                <font-awesome-icon :icon="['fad', 'sign-out']" />
                            </span>
                            <span class="aside-menu-label">Uitloggen</span>
                        </button>
                    </li>
                </ul>
            </nav>
        </div>
    </aside>
</template>

<script>
import {PublicClientApplication} from '@azure/msal-browser';
import {saveAs} from 'file-saver';

export default {
    name: 'Aside',
    props: {
        hasPermissions: Boolean
    },
    methods: {
        async signOut() {
            const msalInstance = new PublicClientApplication(
                this.$store.state.msalConfig);

            await msalInstance
                .logoutRedirect({})    
        },

        async downloadGateLog() {
            const now = new Date();
            
            const timeZoneOffset = - now.getTimezoneOffset();

            const result = await this.$axiosInstance.get(`${process.env.VUE_APP_API}/GateLog?timeZoneOffsetInMinutes=${timeZoneOffset}`, {responseType: 'blob'});
            const contentDispositionHeader = result.headers['content-disposition'];

            let fileName = `export ${now.toLocaleDateString()}.csv`

            if(!(contentDispositionHeader === undefined)){
                fileName = contentDispositionHeader.split('filename=')[1];
            }    

            const blob = new Blob([result.data]);
            saveAs(blob, fileName);
        }
    }
}
</script>

<style lang="scss" scoped>
.aside {
    flex: 0 0 auto;
    width: $aside-width;
    height: 100vh;
    background-color: $secondary;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    position: sticky;
    left: 0;
    top: 0;

    .aside-logo {
        width: 10rem;
        height: auto;
        margin: ($aside-spacer * 1.25);
    }

    .aside-menu {
        padding: 0;
        margin: 0;
        list-style-type: none;
    }

    .aside-menu-link {
        display: flex;
        align-items: center;
        font-size: $font-size-l;
        font-weight: 700;
        color: rgba($white, 0.35);
        text-decoration: none;
        padding: $aside-spacer $aside-spacer $aside-spacer ($aside-spacer * 0.25);
        &:hover {
            color: rgba($white, 0.85);
        }

        &.router-link-active {
            background-color: $secondary-dark;
            color: rgba($white, 1);
        }
    }

    .aside-menu-icon {
        flex: 0 0 auto;
        width: 3rem;
        text-align: center;
    }

    .aside-menu-label {
        flex: 1 1 auto;
    }

    .aside-bottom {
        margin-top: auto;
    }

    button {
        all: unset;
        cursor: pointer;
    }
}
</style>