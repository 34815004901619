<template>
    <div class="page-loading" v-if="isLoading">
        <font-awesome-icon :icon="['fad', 'spinner-third']" spin/>
    </div>

    <div class="container-fluid">
        <header class="main-header">
            <h1 class="main-title">Object detail</h1>
        </header>

        <form @submit.prevent="formSubmit">
            <div class="row">
                <div class="col">
                    <div class="card">
                        <div class="card-body">
                            <h5>Algemeen</h5>
                            <div class="row g-3">
                                <div class="col-md-6">
                                    <label for="">Naam <span class="required">*</span></label>
                                    <input
                                            type="text"
                                            class="form-control"
                                            name="name"
                                            v-model="object.name"
                                            :class="{ 'is-invalid': v$.object.name.$error }"
                                    />
                                    <div class="invalid-feedback" v-if="v$.object.name.$error">
                                        {{ requiredMsg }}
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label for="">Type <span class="required">*</span></label>
                                    <input
                                            type="text"
                                            class="form-control"
                                            name="type"
                                            v-model="object.type"
                                            :class="{ 'is-invalid': v$.object.type.$error }"
                                    />
                                    <div class="invalid-feedback" v-if="v$.object.type.$error">
                                        {{ requiredMsg }}
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label for="">Afkorting <span class="required">*</span></label>
                                    <input
                                            type="text"
                                            class="form-control"
                                            name="abbreviation"
                                            v-model="object.abbreviation"
                                            :class="{ 'is-invalid': v$.object.abbreviation.$error }"
                                    />
                                    <div class="invalid-feedback" v-if="v$.object.abbreviation.$error">
                                        {{ requiredMsg }}
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label for="">Rayon</label>
                                    <input
                                            type="text"
                                            class="form-control"
                                            name="rayon"
                                            v-model="object.rayon"
                                            :class="{ 'is-invalid': v$.object.rayon.$error }"
                                    />
                                    <div class="invalid-feedback" v-if="v$.object.rayon.$error">
                                        {{ requiredMsg }}
                                    </div>
                                </div>

                                <h5>Coördinaten</h5>
                                <div class="col-md-6">
                                    <label for="">WGS Breedtegraad <span class="required">*</span></label>
                                    <input
                                            type="number"
                                            step="0.000000000000001"
                                            class="form-control"
                                            name="latitude"
                                            v-model="object.latitude"
                                            :class="{ 'is-invalid': v$.object.latitude.$error }"
                                    />
                                    <div class="invalid-feedback" v-if="v$.object.latitude.$error">
                                        {{ requiredMsg }}
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label for="">WGS Lengtegraad <span class="required">*</span></label>
                                    <input
                                            type="number"
                                            step="0.000000000000001"
                                            class="form-control"
                                            name="longitude"
                                            v-model="object.longitude"
                                            :class="{ 'is-invalid': v$.object.longitude.$error }"
                                    />
                                    <div class="invalid-feedback" v-if="v$.object.longitude.$error">
                                        {{ requiredMsg }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="actions">
                <a v-if="this.$route.params.id !== undefined && this.$route.params.id !== null" class="btn btn-red ms-3" @click="sendDelete()">Verwijderen</a>
                <router-link :to="{ name: 'ObjectsOverview' }" class="btn btn-gray ms-3"
                >Annuleren
                </router-link
                >
                <button type="submit" class="btn btn-primary-dark ms-3">Opslaan</button>
            </div>
        </form>
    </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators"; <span class="required">*</span>

export default {
    name: "ObjectDetail",
    setup() {
        return {v$: useVuelidate()};
    },
    data() {
        return {
            isLoading: false,
            requiredMsg: "Dit veld is verplicht",
            object: {},
        };
    },

    validations() {
        return {
            object: {
                id: {},
                name: {required},
                latitude: {required},
                longitude: {required},
                abbreviation: {required},
                rayon: {},
                type: {},
            },
        };
    },

    methods: {

        getData() {
            if (this.$route.params.id === undefined || this.$route.params.id === null) return;

            this.isLoading = true;

            Promise.all([
                    this.$axiosInstance.get(`${process.env.VUE_APP_API}/Object/${this.$route.params.id}`),
                ]
            ).then(([object]) => {
                // Fill object data
                this.object = object.data;
            })
                .catch(() => {
                    this.$toast.open({
                        message: 'Object informatie kon niet opgehaald worden. Probeer het a.u.b. opnieuw.',
                        type: 'error',
                        position: 'top-right',
                        duration: 5000
                    })
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },

        sendPostOrPut() {
            if (this.$route.params.id !== undefined && this.$route.params.id !== null) {
                this.sendPutData();
            } else {
                this.sendPostData();
            }
        },

        sendPutData() {
            this.isLoading = true;

            this.$axiosInstance.put(`${process.env.VUE_APP_API}/Object/${this.$route.params.id}`, this.object)
                .then(() =>
                    this.$toast.open({
                        message: 'Object successvol opgeslagen!',
                        position: 'top-right',
                        duration: 5000
                    })
                )
                .catch(() => {
                    this.$toast.open({
                        message: 'Opslaan mislukt. Probeer het a.u.b. opnieuw.',
                        type: 'error',
                        position: 'top-right',
                        duration: 5000
                    })
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },

        sendPostData() {
            this.isLoading = true;

            this.$axiosInstance.post(`${process.env.VUE_APP_API}/Object`, this.object)
                .then(() => {
                        this.$toast.open({
                                message: 'Object successvol opgeslagen!',
                                position: 'top-right',
                                duration: 5000
                            }
                        );
                        this.$router.push("/objecten");
                    }
                ).catch(() => {
                this.$toast.open({
                    message: 'Opslaan mislukt. Probeer het a.u.b. opnieuw.',
                    type: 'error',
                    position: 'top-right',
                    duration: 5000
                })
            })
                .finally(() => {
                    this.isLoading = false;
                });
        },

        sendDelete() {
            this.isLoading = true;

            this.$axiosInstance.delete(`${process.env.VUE_APP_API}/Object/${this.$route.params.id}`, this.object)
                .then(() => {
                        this.$toast.open({
                                message: 'Object successvol verwijderd!',
                                position: 'top-right',
                                duration: 5000
                            }
                        );
                        this.$router.push("/objecten");
                    }
                ).catch( ()=> {
                this.$toast.open({
                    message: 'Verwijderen mislukt. Probeer het a.u.b. opnieuw.',
                    type: 'error',
                    position: 'top-right',
                    duration: 5000
                })
            })
                .finally(() => {
                    this.isLoading = false;
                });
        },

        formSubmit() {
            this.v$.$validate();
            if (!this.v$.$error) {
                this.sendPostOrPut();
            }
        },
    },
    mounted() {
        this.getData();
    },
    watch: {
        '${route.params.id}': {
            handler: function () {
                this.getData();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.invalid-feedback {
  display: block;
}

.actions {
  margin-top: $spacer * 1.5;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .btn {
    min-width: 7.5rem;
  }
}

.btn-red {
  background-color: red;
}

.card, .card-body {
  width: 100%;
}
</style>
