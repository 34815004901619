<template>
    <div class="page-loading" v-if="isLoading">
        <font-awesome-icon :icon="['fad', 'spinner-third']" spin/>
    </div>

    <div class="container-fluid">
        <header class="main-header">
            <h1 class="main-title">Poorten overzicht</h1>
        </header>

        <div class="overview-list">
            <table class="table">
                <thead>
                <tr class="overview-list-header">
                    <td></td>
                    <td>Naam</td>
                    <td>Adres</td>
                    <td>Poort nummer</td>
                    <td>Aantal aanwezig</td>
                    <td>&nbsp;</td>
                </tr>
                </thead>
                <tbody>
                <tr v-for="gate in gates" v-bind:key="gate.id"
                    @click="$router.push(`/poorten/${gate.id}`)" class="overview-list-item">
                    <td class="flex-1">
                        <figure class="overview-thumbnail" v-if="gate.coverImageDataUrl">
                            <img :src="gate.coverImageDataUrl"/>
                        </figure>
                        <figure class="overview-thumbnail" v-else-if="!gate.hasImage">
                            <img src="@/assets/img/placeholder.png"/>
                        </figure>
                        <div class="overview-thumbnail-loading" v-else>
                            <font-awesome-icon :icon="['fad', 'spinner-third']" spin/>
                        </div>
                    </td>
                    <td class="flex-1">{{ gate.name }}</td>
                    <td class="flex-1">
                        {{ gate.streetNameAndNumber }}<br/>
                        {{ gate.postalCodeAndCity }}
                    </td>
                    <td class="flex-1"> {{ gate.sipNumber }}</td>
                    <td class="flex-1">{{ gate.activeGeoFenceUsers }}</td>
                    <td class="overview-icon">
                        <font-awesome-icon :icon="['far', 'angle-right']"/>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    name: "GatesOverview",
    data() {
        return {
            isLoading: false,
            gates: []
        }
    },

    methods: {
        async getData() {
            this.isLoading = true;

            this.$axiosInstance.get(`${process.env.VUE_APP_API}/Gates`)
                .then(async response => {
                    this.gates = response.data;
                })
                .catch(() => {
                    this.$toast.open({
                        message: 'Poorten ophalen niet gelukt. Probeer het a.u.b. opnieuw.',
                        type: 'error',
                        position: 'top-right',
                        duration: 5000
                    })
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
    },

    mounted() {
        this.getData();
    }
};
</script>

<style lang="scss" scoped>
.overview-list-header td {
  vertical-align: middle;
  padding: $spacer;
}

.overview-list {
  .overview-list-item {
    margin-bottom: -$card-border-width;
    padding: $spacer;
    background-color: $white;
    border: $card-border-width solid $card-border-color;
    text-decoration: none;
    color: $body-color;

    td {
      vertical-align: middle;
      padding: $spacer;
    }

    &:nth-child(even) {
      background-color: tint-color($secondary, 96%);
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      cursor: pointer;
      color: $body-color;
      background-color: tint-color($primary, 90%);
    }

    .overview-thumbnail {
      width: 4rem;
      height: 4rem;
      margin: 0 {
        right: $spacer;
      }
      padding: 0;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    .overview-thumbnail-loading {
      width: 4rem;
      height: 4rem;
      margin: 0 {
        right: $spacer;
      }
      padding: 0;
      border-radius: 50%;
      overflow: hidden;

      svg {
        font-size: 4rem;
        margin-bottom: $spacer * 0.5;
      }
    }

    .overview-name {
      flex: 1;
      font-weight: 700;
      font-size: $font-size-l;
      padding-right: $spacer;
    }

    .overview-address {
      flex: 1;
      padding-right: $spacer;
    }

    .overview-numbers {
      flex: 1;
    }

    .overview-geo-fence-users {
      flex: 1;
    }

    .overview-icon {
      flex: 0;
      font-size: 1.75rem;
      font-size: $font-size-xl;
    }
  }
}

.flex-1 {
  flex: 1;
}
</style>
