<template>
    <div class="page-loading" v-if="isLoading">
        <font-awesome-icon :icon="['fad', 'spinner-third']" spin/>
    </div>

    <div class="container-fluid">
        <header class="main-header">
            <h1 class="main-title">Poort detail</h1>
        </header>

        <form @submit.prevent="formSubmit">
            <div class="row">
                <div class="col-md-6">
                    <div class="card">
                        <div class="card-body">
                            <h5>Algemeen</h5>
                            <div class="form-group">
                                <label for="">Poort naam <span class="required">*</span></label>
                                <input
                                        type="text"
                                        class="form-control"
                                        name="organization"
                                        v-model="gate.name"
                                        :class="{ 'is-invalid': v$.gate.name.$error }"
                                />
                                <div class="invalid-feedback" v-if="v$.gate.name.$error">
                                    {{ requiredMsg }}
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="">SIP nummer <span class="required">*</span></label>
                                <input
                                        type="text"
                                        class="form-control"
                                        name="sip"
                                        v-model="gate.sipNumber"
                                        :class="{ 'is-invalid': v$.gate.sipNumber.$error }"
                                        disabled
                                />
                                <div class="invalid-feedback" v-if="v$.gate.sipNumber.$error">
                                    {{ requiredMsg }}
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="">Straat + nr <span class="required">*</span></label>
                                <input
                                        type="text"
                                        class="form-control"
                                        name="street-address"
                                        v-model="gate.streetNameAndNumber"
                                        :class="{ 'is-invalid': v$.gate.streetNameAndNumber.$error }"
                                />
                                <div class="invalid-feedback" v-if="v$.gate.streetNameAndNumber.$error">
                                    {{ requiredMsg }}
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="">Postcode + plaats <span class="required">*</span></label>
                                <input
                                        type="text"
                                        class="form-control"
                                        name="postal-code"
                                        v-model="gate.postalCodeAndCity"
                                        :class="{ 'is-invalid': v$.gate.postalCodeAndCity.$error }"
                                />
                                <div class="invalid-feedback" v-if="v$.gate.postalCodeAndCity.$error">
                                    {{ requiredMsg }}
                                </div>
                            </div>

                            <h5 class="mt-4">Afbeelding</h5>
                            <div class="form-group">
                                <label class="custom-upload-wrapper">
                                    <input
                                            type="file"
                                            accept="image/*"
                                            id="imgUpload"
                                            name="image"
                                            @change="previewImage"
                                    />
                                    <img
                                            v-if="this.gate.coverImageDataUrl"
                                            id="imagePreview"
                                            :src="this.gate.coverImageDataUrl"
                                    />
                                    <img
                                            v-else
                                            id="imagePreview"
                                            src="@/assets/img/placeholder.png"
                                    />
                                    <div class="upload-text">Upload afbeelding</div>
                                </label>
                            </div>
                            <h5>Coördinaten</h5>
                            <div class="form-group">

                                <label for="">WGS Breedtegraad <span class="required">*</span></label>
                                <input
                                        type="number"
                                        step="0.000000000000001" class="form-control"
                                        name="latitude"
                                        v-model="gate.latitude"
                                        :class="{ 'is-invalid': v$.gate.latitude.$error }"
                                />
                                <div class="invalid-feedback" v-if="v$.gate.latitude.$error">
                                    {{ requiredMsg }}
                                </div>
                            </div>
                            <div class="form-group">

                                <label for="">WGS Lengtegraad <span class="required">*</span></label>
                                <input
                                        type="number"
                                        step="0.000000000000001" class="form-control"
                                        name="longitude"
                                        v-model="gate.longitude"
                                        :class="{ 'is-invalid': v$.gate.longitude.$error }"
                                />
                                <div class="invalid-feedback" v-if="v$.gate.longitude.$error">
                                    {{ requiredMsg }}
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="">Straal (meters) <span class="required">*</span></label>
                                <input
                                        type="number"
                                        class="form-control"
                                        name="longitude"
                                        v-model="gate.range"
                                        :class="{ 'is-invalid': v$.gate.range.$error }"
                                />
                                <div class="invalid-feedback" v-if="v$.gate.range.$error">
                                    {{ requiredMsg }}
                                </div>
                            </div>
                            <h5>Openingstijden</h5>
                            <div class="form-group">
                                <label for="openFrom">Open van <span class="required">*</span></label>
                                <input type="time" name="openFrom" id="openFrom" class="form-control"
                                       v-model="gate.openFrom"
                                       :class="{ 'is-invalid': v$.gate.openFrom.$error }" pattern="[0-9]{2}:[0-9]{2}">
                                <div class="invalid-feedback" v-if="v$.gate.openFrom.$error">
                                    {{ requiredMsg }} en het open vanaf tijdstip moet voor het open tot tijdstip
                                    liggen.
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="openUntil">Open tot <span class="required">*</span></label>
                                <!--Pattern is a fallback for browsers that do not support time input-->
                                <input type="time" name="openUntil" id="openUntil" class="form-control"
                                       v-model="gate.openUntil"
                                       :class="{ 'is-invalid': v$.gate.openUntil.$error }" pattern="[0-9]{2}:[0-9]{2}">
                                <div class="invalid-feedback" v-if="v$.gate.openUntil.$error">
                                    {{ requiredMsg }} en het open tot tijdstip moet na het open vanaf tijdstip liggen.
                                </div>
                            </div>
                            <div class="invalid-feedback">
                                <p>Hierboven genoemde openingstijden zijn alleen geldig op werkdagen.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="row">
                        <div class="col">
                            <div class="card belboom">
                                <div class="card-body">
                                    <h5>Gebruikers belboom</h5>
                                    <div class="form-group">
                                        <div class="input-group">
                                            <select
                                                    class="form-select"
                                                    name="addToCallListName"
                                                    v-model="addToCallListId"
                                            >
                                                <option disabled selected value="">Kies gebruiker</option>
                                                <option
                                                        v-for="user in inactiveUsers"
                                                        :key="user.userId"
                                                        :value="user.userId"
                                                >
                                                    {{ user.firstName }} {{ user.lastName }}
                                                    {{ user.isDisabled ? '(Uitgeschakeld)' : '' }}
                                                </option>
                                            </select>
                                            <select
                                                    class="form-select belboom-position"
                                                    name="addToCallListSequence"
                                                    v-model="addToCallListSequence"
                                            >
                                                <option value="0" selected>Niet bellen</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                            </select>
                                            <button
                                                    type="button"
                                                    class="btn btn-secondary"
                                                    @click="addToCallList"
                                            >
                                                <font-awesome-icon :icon="['far', 'plus']"/>
                                            </button>
                                        </div>
                                    </div>

                                    <ul class="list-group">
                                        <li
                                                class="list-group-item"
                                                v-for="user in activeUsers"
                                                :key="user.userId"
                                                v-bind:class="{ 'list-group-item-disabled': user.isDisabled }"
                                        >
                                            {{ user.firstName }} {{ user.lastName }} {{
                                                user.isDisabled ? '(Uitgeschakeld)' : ''
                                            }}
                                            <select
                                                    class="form-select"
                                                    name="sequence"
                                                    v-model="user.sequenceNumber"
                                            >
                                                <option value="0">Niet bellen</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                            </select>
                                            <button
                                                    type="button"
                                                    class="btn text-danger"
                                                    @click="removeFromCallList(user.userId)"
                                            >
                                                <font-awesome-icon :icon="['far', 'times']"/>
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="card belboom">
                                <div class="card-body">
                                    <h5>Gebruikers aanwezig</h5>
                                    <div class="invalid-feedback">
                                        <p>De eerste gebruiker op locatie staat op de eerste plaats in de belboom</p>
                                    </div>
                                    <br>
                                    <p v-if="geoFenceUsers.length === 0">Er zijn momenteel geen gebruikers aanwezig!</p>
                                    <table class="table" v-else>
                                        <thead>
                                        <tr class="overview-list-header">
                                            <td>Prioriteit</td>
                                            <td>Naam</td>
                                            <td>Aanwezig sinds</td>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr class="overview-list-header" v-for="user in geoFenceUsers" v-bind:key="user.userId">
                                            <td>{{ user.sequence }}</td>
                                            <td> {{ user.firstName }} {{ user.lastName }}</td>
                                            <td> {{ renderDate(user.enteredGeoFence) }}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="actions">
                <router-link :to="{ name: 'GatesOverview' }" class="btn btn-gray"
                >Annuleren
                </router-link
                >
                <button type="submit" class="btn btn-primary-dark ms-3">Opslaan</button>
            </div>
        </form>
    </div>
</template>

<script>
import _ from "lodash";
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";

export default {
    name: "GateDetail",
    setup() {
        return {v$: useVuelidate()};
    },
    data() {
        return {
            isLoading: false,
            requiredMsg: "Dit veld is verplicht",
            addToCallListId: "",
            addToCallListSequence: 0,
            gate: {},
            activeUsers: [],
            inactiveUsers: [],
            geoFenceUsers: []
        };
    },

    validations() {
        return {
            gate: {
                name: {required},
                sipNumber: {required},
                streetNameAndNumber: {required},
                postalCodeAndCity: {required},
                latitude: {required},
                longitude: {required},
                range: {required},
                openFrom: {required, openFromMustBeBeforeOpenUntil},
                openUntil: {required, openUntilMustBeAfterOpenFrom},
            },
        };
    },

    methods: {
        getData() {
            this.isLoading = true;

            Promise.all([
                this.$axiosInstance.get(`${process.env.VUE_APP_API}/Gates/Gate?gateId=${this.$route.params.id}`),
                this.$axiosInstance.get(`${process.env.VUE_APP_API}/GateUser/GateUsersSortedBySequenceNumber?gateId=${this.$route.params.id}`),
                this.$axiosInstance.get(`${process.env.VUE_APP_API}/GateUser/NonAddedUsers?gateId=${this.$route.params.id}`),
                this.$axiosInstance.get(`${process.env.VUE_APP_API}/GateUser/UsersWithinGeoFence?gateId=${this.$route.params.id}`)]
            )
                .then(([gate, activeUsers, inactiveUsers, usersWithinGeofence]) => {
                    // Fill gate data
                    this.gate = gate.data;

                    // Fill user data
                    if (!(activeUsers.data === "")) {
                        this.activeUsers = activeUsers.data;
                    }

                    // Fill unused users
                    if (!(inactiveUsers.data === "")) {
                        this.inactiveUsers = inactiveUsers.data;
                    }

                    if (!(usersWithinGeofence.data === "")) {
                        this.geoFenceUsers = usersWithinGeofence.data;
                    }
                })
                .catch(() => {
                    this.$toast.open({
                        message: 'Poort informatie kon niet opgehaald worden. Probeer het a.u.b. opnieuw.',
                        type: 'error',
                        position: 'top-right',
                        duration: 5000
                    })
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },

        sendData() {
            this.isLoading = true;

            this.$axiosInstance.put(`${process.env.VUE_APP_API}/Gates`, this.gate)
                .then(() =>
                    this.activeUsers?.length
                        ? this.$axiosInstance.put(`${process.env.VUE_APP_API}/GateUser`, this.activeUsers)
                        : this.$axiosInstance.put(`${process.env.VUE_APP_API}/GateUser/Remove`, this.gate)
                )
                .then(() =>
                    this.$toast.open({
                        message: 'Poort successvol opgeslagen!',
                        position: 'top-right',
                        duration: 5000
                    })
                )
                .catch(() => {
                    this.$toast.open({
                        message: 'Opslaan mislukt. Probeer het a.u.b. opnieuw.',
                        type: 'error',
                        position: 'top-right',
                        duration: 5000
                    })
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },

        previewImage(event) {
            const input = event.target;

            if (input.files) {
                const reader = new FileReader();

                reader.onload = (e) => {
                    this.gate.coverImageDataUrl = e.target.result;
                };

                this.gate.coverImageDataUrl = input.files[0];
                reader.readAsDataURL(input.files[0]);

                //Post to API and save
                let formData = new FormData();
                formData.append("files", input.files[0]);
                this.$axiosInstance.post(`${process.env.VUE_APP_API}/CoverImages/CoverImage/${this.gate.id}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
            }
        },

        addToCallList() {
            const selectedId = this.addToCallListId;
            const selectedSequence = this.addToCallListSequence;
            const selectedUser = _.find(this.inactiveUsers, {userId: selectedId});
            if (selectedUser) {
                // Add keys/pairs to the selected users
                selectedUser.gateId = this.gate.id;
                selectedUser.sequenceNumber = selectedSequence;

                // Push selected users to activeUsers
                this.activeUsers.push(selectedUser);

                // Remove selected user from inactiveUsers
                _.remove(this.inactiveUsers, selectedUser => selectedUser.userId === selectedId);

                // Reset add form
                this.addToCallListId = '';
                this.addToCallListSequence = 0;
            }
        },

        removeFromCallList(userId) {
            // Storing selected user
            const selectedUser = _.find(this.activeUsers, {userId: userId});

            // Removing from active users
            _.remove(this.activeUsers, selectedUser);

            // Deleting keys/pairs before pushing to inactiveusers
            delete selectedUser.gateId;
            delete selectedUser.sequenceNumber;

            // Pushing selected user in inactiveUsers
            this.inactiveUsers.push(selectedUser);
        },

        renderDate(value) {
            const date = new Date(value);
            return `${date.toLocaleDateString()} - ${date.toLocaleTimeString()}`;
        },

        formSubmit() {
            this.v$.$validate();
            if (!this.v$.$error) {
                this.sendData();
            }
        },
    },
    mounted() {
        this.getData();
    },
    watch: {
        '$route.params.id}': {
            handler: function () {
                this.getData();
            }
        },
    },
};

function openFromMustBeBeforeOpenUntil() {
    return timeToSeconds(this.gate.openFrom) < timeToSeconds(this.gate.openUntil);
}

function openUntilMustBeAfterOpenFrom() {
    return timeToSeconds(this.gate.openUntil) > timeToSeconds(this.gate.openFrom);
}

function timeToSeconds(value) {

    let hoursAndSeconds = value.split(':'); // split it at the colons

    return ((+hoursAndSeconds[0]) * 60 * 60) + ((+hoursAndSeconds[1]) * 60);
}
</script>

<style lang="scss" scoped>
.invalid-feedback {
  display: block;
}

.belboom {
  .form-group {
    .belboom-position {
      max-width: 9rem;
      text-align: right;
      text-align-last: right;
    }

    .btn {
      width: 3.3rem;
      text-align: center;
    }
  }

  .list-group {
    .list-group-item {
      display: flex;
      align-items: center;

      .form-select {
        max-width: 8rem;
        margin-left: auto;
        text-align: right;
        text-align-last: right;
        border: 0;
        border-radius: 0;
      }
    }

    .list-group-item-disabled {
      background-color: #e9ecef;

      .form-select {
        background-color: #e9ecef;
      }
    }
  }
}

.custom-upload-wrapper {
  width: 8em;
  height: 8em;
  border-radius: 50%;
  display: block;
  position: relative;
  overflow: hidden;
  margin-top: $spacer;

  &:hover {
    cursor: pointer;

    .upload-text {
      background-color: rgba($primary-dark, 0.75);
      color: $white;
      z-index: 3;
    }
  }

  #imgUpload {
    visibility: hidden;
    opacity: 0;
    position: absolute;
  }

  #imagePreview {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
  }

  .upload-text {
    @extend h5;
    color: $gray-600;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: $gray-200;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
  }
}

.actions {
  margin-top: $spacer * 1.5;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .btn {
    min-width: 7.5rem;
  }
}

.row {
  margin-bottom: $spacer;
}

.overview-list-header td {
  vertical-align: middle;
  padding: $spacer;
}

.geo-fence-list-group-column {
  padding: $spacer !important;
  vertical-align: middle;
}
</style>
