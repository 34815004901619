<template>
    <div class="page-loading" v-if="isLoading">
        <font-awesome-icon :icon="['fad', 'spinner-third']" spin/>
    </div>

    <div class="container-fluid">
        <header class="main-header main-header-container">
            <h1 class="main-title">Objecten overzicht</h1>
            <router-link to="/objecten/toevoegen">
                <button
                        type="button"
                        class="btn btn-secondary"
                >
                    <font-awesome-icon :icon="['far', 'plus']"/>
                </button>
            </router-link>
        </header>

        <div class="card">
            <div class="card-body">

                <div class="row">
                    <div class="col">
                        <input
                                type="text"
                                class="form-control"
                                name="search"
                                v-model="searchTerm"
                                @input="getData()"
                                placeholder="Zoeken op naam, afkorting of rayon"
                        />
                    </div>
                </div>

                <div class="overview-list">
                    <table class="table">
                        <thead>
                        <tr>
                            <td>Naam</td>
                            <td>Afkorting</td>
                            <td>Type</td>
                            <td>Rayon</td>
                            <td>&nbsp;</td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="object in objects" v-bind:key="object.id"
                            @click="$router.push(`/objecten/${object.id}`)" class="overview-list-item">
                            <td class="overview-name">{{ object.name }}</td>
                            <td class="">{{ object.abbreviation }}</td>
                            <td class="">{{ object.type }}</td>
                            <td class="">{{ object.rayon }}</td>
                            <td class="overview-icon">
                                <font-awesome-icon :icon="['far', 'angle-right']"/>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </div>

    </div>
</template>

<script>

export default {
    name: "ObjectOverview",
    data() {
        return {
            isLoading: false,
            objects: [],
            searchTerm: '',
        }
    },

    methods: {
        getData() {
            this.isLoading = true;

            this.$axiosInstance.get(`${process.env.VUE_APP_API}/Object?searchTerm=${this.searchTerm}`)
                .then(response => {
                    this.objects = response.data;
                })
                .catch(() => {
                    this.$toast.open({
                        message: 'Objecten ophalen niet gelukt. Probeer het a.u.b. opnieuw.',
                        type: 'error',
                        position: 'top-right',
                        duration: 5000
                    })
                })
                .finally(() => {
                    this.isLoading = false;
                });
        }
    },

    mounted() {
        this.getData();
    },
};


</script>

<style lang="scss" scoped>
.main-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.overview-list {
  .overview-list-item {
    margin-bottom: -$card-border-width;
    padding: $spacer;
    background-color: $white;
    color: $body-color;

    &:nth-child(even) {
      background-color: tint-color($secondary, 96%);
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      cursor: pointer;
      color: $body-color;
      background-color: tint-color($primary, 90%);
    }
  }
}
</style>