<template>
  <div class="page-wrapper" v-if="account != undefined">
    <Aside v-bind:has-permissions="hasPermission('poorten.backoffice')" />

    <main class="main" v-if="hasPermission('poorten.backoffice')">
      <router-view />
    </main>
    <main class="main" v-else>
      <header class="main-header">
        <h1 class="main-title">Toegang geweigerd</h1>
      </header>

      Sorry, u heeft geen toegang tot deze pagina. Neem a.u.b. contact op met een beheerder, of log uit d.m.v. de 'Uitloggen' knop links onderin.
    </main>
  </div>
  <div class="page-wrapper" v-else >
    <div class="waiting-for-account">
      <font-awesome-icon :icon="['fad', 'spinner-third']" spin />
    </div>
  </div>
  
</template>

<script>
import Aside from '@/components/Aside.vue'
import { PublicClientApplication } from '@azure/msal-browser';
import {EventType} from '@azure/msal-browser';

export default {
  name: 'App',
  components: {
    Aside
  },
  data() {
    return {
      account: undefined,
      containers: []
    };
  },
  async created() {
    this.$msalInstance = new PublicClientApplication(
      this.$store.state.msalConfig,
    );

    this.$emitter.on(
      'login',
      async function (account) {
        await this.$axiosInstance
          .post(`${process.env.VUE_APP_API}/Permissions`)
          .then(success => localStorage.setItem('permissions', JSON.stringify(success.data)))
          .catch(() => localStorage.removeItem('permissions'));

        this.account = account;
      }.bind(this),
    ),

    this.$emitter.on('logout', () => {
      this.$msalInstance.
      this.account = undefined;
      localStorage.removeItem('permissions');
    });

    this.$msalInstance.addEventCallback((event) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const account = event.payload.account;
        this.$msalInstance.setActiveAccount(account);
        this.$emitter.emit('login', event.payload.account)
      }
    }, error=>{
      console.log('error', error);
    });

    this.$msalInstance.handleRedirectPromise().then(() =>{
      const account = this.$msalInstance.getActiveAccount();

      if(!account){
        let request = {
          scopes: [process.env.VUE_APP_API_SCOPE],
        };

        this.$msalInstance.loginRedirect(request)      
      } else {
        this.$emitter.emit('login', account);
      }
    }).catch(err=>{
      console.log(err);
    });
   },
  methods: {
    hasPermission(permission)
    {
      const permissions = localStorage.getItem('permissions');
      if (!permissions)
      {
        return false;
      }

      return JSON.parse(permissions).indexOf(permission) !== -1;
    }
  },
};
</script>

<style lang="scss">
:root {
  font-size: $font-size-root;
}

body {
  background-color: $background;
  font-family: $main-font;
  font-size: $font-size;
  font-weight: $font-weight;
  line-height: $line-height;
}

.waiting-for-account {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;

  svg {
      font-size: 4rem;
      margin-bottom: $spacer * 0.5;
  }
}
</style>
