<template>
  <div class="page-loading" v-if="isLoading">
    <font-awesome-icon :icon="['fad', 'spinner-third']" spin />
  </div>

  <div class="container-fluid">
    <header class="main-header">
      <h1 class="main-title">Gebruikers</h1>
    </header>

    <form @submit.prevent="formSubmit">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-10 col-xl-8">
            <div class="card gebruikers-beheer">
            <div class="card-body">
                <h5>Beheer gebruikers</h5>
                <div class="form-group">
                <div class="input-group">
                    <select
                    class="form-select"
                    name="addToUserRolesName"
                    v-model="addToUserRolesId"
                    >
                    <option disabled selected value="">Kies gebruiker om toe te voegen</option>
                    <option
                        v-for="user in nonAddedUsers"
                        :key="user.userId"
                        :value="user.userId"
                    >
                        {{ user.firstName }} {{ user.lastName }} {{ user.isDisabled ? '(Uitgeschakeld)': '' }}
                    </option>
                    </select>
                    <button
                    type="button"
                    class="btn btn-secondary"
                    @click="addToUserList"
                    >
                    <font-awesome-icon :icon="['far', 'plus']" />
                    </button>
                </div>
                </div>

                <ul class="list-group">
                <li
                    class="list-group-item"
                    v-for="user in userRoles"
                    :key="user.userId"
                    v-bind:class="{ 'list-group-item-disabled': user.isDisabled || user.isSelf }"
                >
                    {{ user.firstName }} {{ user.lastName }} {{ user.isDisabled ? '(Uitgeschakeld)': '' }}
                    <select
                        class="form-select"
                        name="sequence"
                        v-model="user.role"
                        :disabled="user.isSelf"
                        :style="{ 'background-image': user.isSelf ? 'none' : '' }"
                    >
                    <option value="Member" selected>App-gebruiker</option>
                    <option value="Admin">Beheerder</option>
                    </select>
                    <button
                      type="button"
                      class="btn text-danger"
                      @click="removeFromUserList(user.userId)"
                      :disabled="user.isSelf"
                      :style="{ 'visibility': user.isSelf ? 'hidden' : 'visible' }"
                    >
                    <font-awesome-icon :icon="['far', 'times']" />
                    </button>
                </li>
                </ul>
            </div>
            </div>

            <div class="actions">
              <button type="button" @click="getData()" class="btn btn-gray">Annuleren</button>
              <button type="submit" class="btn btn-primary-dark ms-3">Opslaan</button>
            </div>
        </div>
    </form>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "Roles",
  data() {
    return {
        isLoading: false,
        userRoles: [],
        nonAddedUsers: [],
        addToUserRolesId: ""
    }
  },

  methods: {
    getData() {
      this.isLoading = true;

      Promise.all([
        this.$axiosInstance.get(`${process.env.VUE_APP_API}/UserRoles`),
        this.$axiosInstance.get(`${process.env.VUE_APP_API}/UserRoles/NonAddedUsers`)]
      )
      .then(([userRoles, nonAddedUsers]) => {
        if(!(userRoles.data === ""))
        {
          this.userRoles = userRoles.data;
        }

        if(!(nonAddedUsers.data === "")) {
          this.nonAddedUsers = nonAddedUsers.data;
        }

        this.addToUserRolesId = "";
      })
      .catch(() => {
          this.$toast.open({
            message: 'Gebruiker data kon niet opgehaald worden. Probeer het a.u.b. opnieuw.',
            type: 'error',
            position: 'top-right',
            duration: 5000
          })
      })
      .finally(() => {
        this.isLoading = false;
      })
    },
    addToUserList() {
      const selectedId = this.addToUserRolesId;
      const selectedUser = _.find(this.nonAddedUsers, { userId: selectedId });
      if (selectedUser) {
        selectedUser.role = "Member";

        this.userRoles.push(selectedUser);
        
        _.remove(this.nonAddedUsers, selectedUser => selectedUser.userId === selectedId);

        this.addToUserRolesId = '';
      }
    },

    removeFromUserList(userId) {
      const selectedUser = _.find(this.userRoles, { userId: userId });

      _.remove(this.userRoles, selectedUser);

      delete selectedUser.role;

      this.nonAddedUsers.push(selectedUser);
    },

    formSubmit() {
      this.isLoading = true;

      this.$axiosInstance.put(`${process.env.VUE_APP_API}/UserRoles`, this.userRoles)
        .then(() => 
          this.$toast.open({
            message: 'Gebruikers successvol opgeslagen!',
            position: 'top-right',
            duration: 5000
          })
        )
        .catch(() => {
          this.$toast.open({
            message: 'Opslaan mislukt. Probeer het a.u.b. opnieuw.',
            type: 'error',
            position: 'top-right',
            duration: 5000
          })
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },

  mounted() {
    this.getData();
  }
};
</script>

<style lang="scss" scoped>

.gebruikers-beheer {
  .form-group {
    .btn {
      width: 3.3rem;
      text-align: center;
    }
  }

  .list-group {
    .list-group-item {
      display: flex;
      align-items: center;
      .form-select {
        max-width: 10rem;
        margin-left: auto;
        border: 0;
        border-radius: 0;
      }
    }

    .list-group-item-disabled {
      background-color: #e9ecef;

      .form-select {
        background-color: #e9ecef;
      }
    }
  }
}

.actions {
  margin-top: $spacer * 1.5;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .btn {
    min-width: 7.5rem;
  }
}

</style>